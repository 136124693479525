import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';


// Initialize Amplitude with the global variables
const apiKey = window.AMPLITUDE_API_KEY;
const userId = window.CURRENT_USER_ID || null;

// Initialize Amplitude with the global variables
amplitude.init(apiKey, userId,
  {
    minIdLength: 1,
    defaultTracking: true,
    serverUrl: '/ampl-tracker/2/httpapi', // Proxy this through our server to bypass ad-blockers
  }
);
const sessionReplayTracking = sessionReplayPlugin({
  sampleRate: 0.5,
});
amplitude.add(sessionReplayTracking);
